#navigationLinks a {
    font-size: 1.5em;
}

/* Applied when navigation links are expanded (desktop) */
@media (min-width: 992px) {
    #navigationLinks {
        flex-grow: unset;
    }
    #navigationLinks a {
        margin-left: 15px;
    }
    #desktopLogo {
        display: unset;
    }
    #tabletLogo {
        display: none;
    }
}

/* Applied when navigation links are collapsed (tablet) */
@media (max-width: 991px) {
    #navigationLinks {
        flex-grow: unset;
    }
    #navigationLinks a {
        margin-left: 0px;
    }
    #desktopLogo {
        display: none;
    }
    #tabletLogo {
        display: unset;
    }
}

/* Applied when navigation links are collapsed (mobile) */
@media (max-width: 575px) {
    #navigationLinks {
        flex-grow: unset;
    }
    #navigationLinks a {
        margin-left: 0px;
    }
    #desktopLogo {
        display: none;
    }
    #tabletLogo {
        display: none;
    }
}